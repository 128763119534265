<template>
  <div>
    <div class="title">修改密码</div>
    <div class="form">
      <el-form ref="form" :model="form" :rules="rules" label-width="180px" style="margin-left: 230px;" size="small">
        <el-form-item label="旧密码：" prop="opwd">
          <el-input type="password" v-model.trim="form.opwd" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="npwd">
          <el-input type="password" maxlength="11" v-model.trim="form.npwd" placeholder="密码的长度为6-11位" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码：" prop="nnpwd">
          <el-input type="password" maxlength="11" v-model.trim="form.nnpwd" placeholder="请输入重复密码" style="width: 260px;"></el-input>
        </el-form-item>
        <el-form-item label="手机验证码：" prop="code">
          <div style="display:flex;align-items: center;">
            <div style="width: 150px;margin-right:10px;">
              <el-input v-model.trim="form.code"></el-input>
            </div>
            <div class="code-btn" @click="sendCode" :class="{ disabled: !show }">
              {{ show ? "发送验证码" : `${timeCount}s` }}
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="submit-btn" @click="onSubmit('form')">确定</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.nnpwd !== '') {
          this.$refs.form.validateField('nnpwd')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value.length < 6) {
        callback(new Error('请输入至少6个字符'))
      } else if (value !== this.form.npwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        opwd: '',
        npwd: '',
        nnpwd: '',
        code: ''
      },
      rules: {
        opwd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        npwd: [
          { required: true, validator: validatePass, trigger: 'blur' },
          { min: 6, message: '长度至少6个字符', trigger: 'blur' }
        ],
        nnpwd: [{ required: true, validator: validatePass2, trigger: 'blur' }],
        code: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }]
      },
      sendCodeId: '',
      submitBtnId: '',

      timeCount: '',
      show: true,
      timer: null
    }
  },
  created () {
    this.getBtnIds() // 获取按钮id
  },
  computed: {
    userInfo () {
      return this.$store.state.UserInfo
    }
  },
  methods: {
    getBtnIds () {
      let params = {
        process_id: '00000203', // 流程ID
        nodeInfo: {} // 传参  查询则是查询条件  可传可不传等
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(result => {
          const { data } = result
          this.sendCodeId = data.button.NodeId_2
          this.submitBtnId = data.button.NodeId_1
        })
        .catch(err => { })
    },
    sendCode () {
      if (!this.show) {
        return
      }

      let params = {
        mobileNumber: this.userInfo.phone
      }
      this.$post('92903d41-c09f-47e1-896c-7768aaa4ecb6', params)
        .then(res => {
          if (res.succeed == 0) {
            this.$message({
              message: res.errorMsg,
              type: 'success'
            })
            this.timeCount = 60
            if (!this.timer) {
              this.show = false
              this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                  this.timeCount--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
            this.timeCount = 60
            if (!this.timer) {
              this.show = false
              this.timer = setInterval(() => {
                if (this.timeCount > 0) {
                  this.timeCount--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          }
        })
        .catch(err => { })
    },
    onSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            process_id: '00000203',
            execution_type: 'set', // get 代表读请求 获取数据,  set	代表写请求 编辑等操作
            nextProcessNode: this.submitBtnId, // 上面接口的  button.NodeId_1字段的值
            nodeInfo: {
              userId: '' /* 用户id（不传或传空，表示更新当前用户的密码） */,
              oldPassword: this.form.opwd /* 旧密码 */,
              newPassword: this.form.npwd /* 新密码 */,
              mobilePhoneVerificationCode: this.form.code /* 手机验证码 */
            }
          }
          this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
            .then(res => {
              if (res.succeed == 0) {
                this.$message({
                  message: res.errorMsg,
                  type: 'success'
                })
                // 清空form表单
                this.$router.push('/userInfo/userCenter')
              }
            })
            .catch(err => { })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    clearForm () {
      Object.keys(this.form).forEach(item => {
        this.form[item] = ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.form {
  margin: 10px 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  .code-btn {
    background-color: #2d5171;
    color: #fff;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    width: 100px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .submit-btn {
    background-color: #2d5171;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    width: 66px;
    padding: 5px 20px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
